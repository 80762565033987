.panel-two-new {
  margin-top: 80px;
}

.panel-two-new .title-img {
  width: 157px;
  height: 140px;
  margin-right: 24px;
}

.panel-two-new .title {
  font-size: 40px;
  font-weight: 900;
  line-height: 56px;
}

.panel-two-new .content {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.panel-three-new {
  margin-top: 40px;
}

.panel-three-new .three-item {
  text-align: left;
  border: 4px solid #fff;
  border-radius: 32px;
  width: 340px;
  height: 400px;
  margin-top: 30px;
  padding: 40px;
}

.panel-three-new .three-item-title {
  letter-spacing: -.02em;
  text-align: left;
  margin-top: 8px;
  font-size: 40px;
  font-weight: 900;
  line-height: 56px;
}

.panel-three-new .three-item-sub-title {
  color: #9aadce;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
}

.panel-three-new .three-item-content {
  text-align: left;
  font-family: HarmonyOS_Sans_Regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.panel-three-new .item-arrow {
  margin-top: 30px;
}

.panel-three-new .three-content {
  border: 4px solid #fff;
  border-radius: 32px;
  margin-top: 24px;
  margin-bottom: 112px;
  padding: 24px 40px;
}

.panel-three-new .three-content .three-content-icon {
  margin-right: 40px;
}

.panel-three-new .three-content .three-content-title {
  letter-spacing: -.02em;
  text-align: left;
  color: #9aadce;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
}

.panel-three-new .three-content .three-content-sub {
  text-align: left;
  color: #9aadce;
  margin-right: 32px;
  font-size: 28px;
  font-weight: 700;
  line-height: 20px;
}

.panel-three-new .three-content .three-content-tips {
  text-align: left;
  margin-right: 32px;
  font-family: HarmonyOS_Sans_Regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.panel-four-new-title {
  margin-bottom: 44px;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 56px;
}

.panel-four-new .four-new-item {
  width: 268px;
}

.panel-four-new .four-new-item img {
  width: 268px;
  height: 268px;
  margin-bottom: 10px;
}

.panel-four-new .four-new-item .four-new-item-title {
  color: #fff;
  margin-bottom: 8px;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.panel-four-new .four-new-item .four-new-item-sub-title {
  color: #fff;
  font-family: HarmonyOS_Sans_Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.panel-four-new .group-btns {
  align-items: center;
  gap: 28px;
  margin-top: 62px;
  display: flex;
}

.panel-four-new .group-btns .btn {
  background: var(--GroupFi-100, #3671ee);
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 184px;
  height: 48px;
  padding: 0 12px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
}

.panel-four-new .group-btns .btn:hover {
  background: var(--GroupFi-80, #3671eecc);
}

.small-screen .panel-two-new > div {
  flex-direction: column;
  align-items: center;
  padding-top: 0 !important;
}

.small-screen .panel-two-new > div .title-img {
  margin-bottom: 30px;
  margin-right: 0;
}

.small-screen .panel-two-new > div .title {
  text-align: center;
  font-size: 30px;
}

.small-screen .panel-two-new > div .content {
  text-align: center;
  font-size: 22px;
}

.small-screen .panel-three-new > div {
  flex-direction: column;
  align-items: center;
}

.small-screen .panel-three-new > div .three-item {
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding: 24px;
}

.small-screen .panel-three-new > div .three-item .three-item-title {
  font-size: 30px;
}

.small-screen .panel-three-new > div .three-item .three-item-sub-title {
  font-size: 20px;
}

.small-screen .panel-three-new > div .three-item .three-item-content {
  font-size: 15px;
}

.small-screen .panel-three-new > div .item-arrow {
  display: none;
}

.small-screen .panel-three-new > div .arrow-right {
  margin-top: 32px;
  margin-bottom: 32px;
  transform: rotate(90deg);
}

.small-screen .panel-three-new > div.three-content {
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 32px auto;
  padding: 24px;
}

.small-screen .panel-three-new > div.three-content .three-content-icon {
  margin-right: 20px;
}

.small-screen .panel-three-new > div.three-content .three-content-title {
  font-size: 22px;
}

.small-screen .panel-three-new > div .three-content-right {
  margin-top: 24px;
  display: block;
}

.small-screen .panel-three-new > div .three-content-right .three-content-sub {
  text-align: center;
  margin-right: 0;
}

.small-screen .panel-three-new > div .three-content-right .three-content-tips {
  text-align: center;
  margin-top: 24px;
  margin-right: 0;
}

.small-screen .panel-four-new {
  box-sizing: border-box;
  padding: 0 16px;
}

.small-screen .panel-four-new .minds-con {
  flex-direction: column;
  align-items: flex-start;
}

.small-screen .panel-four-new .panel-four-new-title {
  margin-bottom: 24px;
  font-size: 30px;
}

.small-screen .panel-four-new .four-new-item {
  width: 80vw;
  margin-bottom: 40px;
}

.small-screen .panel-four-new .four-new-item img {
  width: 80vw;
  height: 80vw;
  margin-bottom: 10px;
}

.small-screen .panel-four-new .four-new-item .four-new-item-title {
  color: #fff;
  margin-bottom: 8px;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.small-screen .panel-four-new .four-new-item .four-new-item-sub-title {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

/*# sourceMappingURL=groupFi.36319842.css.map */
