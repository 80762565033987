.panel {
    &-two-new {
        margin-top: 80px;
        .title-img {
            width: 157px;
            height: 140px;
            margin-right: 24px;
        }
        .title {
            font-size: 40px;
            font-weight: 900;
            line-height: 56px;
        }
        .content {
            font-size: 20px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    &-three-new {
        margin-top: 40px;
        .three-item {
            width: 340px;
            height: 400px;
            border: 4px solid #fff;
            border-radius: 32px;
            padding: 40px;
            margin-top: 30px;
            text-align: left;
        }
        .three-item-title {
            margin-top: 8px;
            font-size: 40px;
            font-weight: 900;
            line-height: 56px;
            letter-spacing: -0.02em;
            text-align: left;
        }
        .three-item-sub-title {
            font-size: 28px;
            font-weight: 700;
            line-height: 32px;
            color: #9aadce;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 8px;
        }
        .three-item-content {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            font-family: HarmonyOS_Sans_Regular;
        }
        .item-arrow {
            margin-top: 30px;
        }
        .three-content {
            margin-top: 24px;
            border: 4px solid #fff;
            padding: 24px 40px;
            border-radius: 32px;
            margin-bottom: 112px;
            .three-content-icon {
                margin-right: 40px;
            }
            .three-content-title {
                font-size: 28px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: -0.02em;
                text-align: left;
                color: #9aadce;
            }
            .three-content-sub {
                font-size: 28px;
                font-weight: 700;
                line-height: 20px;
                text-align: left;
                margin-right: 32px;
                color: #9aadce;
            }
            .three-content-tips {
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                margin-right: 32px;
                font-family: HarmonyOS_Sans_Regular;
            }
        }
    }
    &-four-new {
        &-title {
            font-size: 40px;
            font-style: normal;
            font-weight: 900;
            line-height: 56px; /* 140% */
            margin-bottom: 44px;
        }
        .four-new-item {
            width: 268px;
            img {
                width: 268px;
                height: 268px;
                margin-bottom: 10px;
            }
            .four-new-item-title {
                color: #fff;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px; /* 100% */
                margin-bottom: 8px;
            }
            .four-new-item-sub-title {
                font-family: HarmonyOS_Sans_Regular;
                color: #fff;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
            }
        }
        .group-btns {
            display: flex;
            align-items: center;
            gap: 28px;
            margin-top: 62px;
            .btn {
                display: flex;
                width: 184px;
                height: 48px;
                padding: 0px 12px;
                font-size: 18px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                border-radius: 16px;
                background: var(--GroupFi-100, #3671ee);
                cursor: pointer;
                color: #fff;
                text-decoration: none;
                &:hover {
                    background: var(--GroupFi-80, #3671eecc);
                }
            }
        }
    }
}

.small-screen {
    .panel-two-new > div {
        flex-direction: column;
        align-items: center;
        padding-top: 0 !important;
        .title-img {
            margin-right: 0;
            margin-bottom: 30px;
        }
        .title {
            text-align: center;
            font-size: 30px;
        }
        .content {
            text-align: center;
            font-size: 22px;
        }
    }
    .panel-three-new > div {
        flex-direction: column;
        align-items: center;
        .three-item {
            width: 90%;
            margin: 0 auto;
            padding: 24px;
            height: auto;
            .three-item-title {
                font-size: 30px;
            }
            .three-item-sub-title {
                font-size: 20px;
            }
            .three-item-content {
                font-size: 15px;
            }
        }
        .item-arrow {
            display: none;
        }
        .arrow-right {
            transform: rotate(90deg);
            margin-top: 32px;
            margin-bottom: 32px;
        }
        &.three-content {
            width: 90%;
            margin: 32px auto;
            flex-direction: column;
            align-items: center;
            padding: 24px;
            .three-content-icon {
                margin-right: 20px;
            }
            .three-content-title {
                font-size: 22px;
            }
        }
        .three-content-right {
            margin-top: 24px;
            display: block;
            .three-content-sub {
                text-align: center;
                margin-right: 0;
            }
            .three-content-tips {
                text-align: center;
                margin-top: 24px;
                margin-right: 0;
            }
        }
    }
    .panel-four-new {
        padding: 0 16px;
        box-sizing: border-box;
        .minds-con {
            flex-direction: column;
            align-items: flex-start;
        }
        .panel-four-new-title {
            font-size: 30px;
            margin-bottom: 24px;
        }
        .four-new-item {
            width: 80vw;
            margin-bottom: 40px;
            img {
                width: 80vw;
                height: 80vw;
                margin-bottom: 10px;
            }
            .four-new-item-title {
                color: #fff;
                font-size: 21px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 100% */
                margin-bottom: 8px;
            }
            .four-new-item-sub-title {
                color: #fff;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
            }
        }
    }
}
